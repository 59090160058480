@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BerkeleyMono";
  src: url("/typefaces/berkeley-mono.woff2");
  font-style: normal;
  font-weight: normal;
}

html,
body {
  background: "#FAFAFA";
}
